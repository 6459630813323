import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";

const COLORS = [
  "#8884d8", "#82ca9d", "#ffc658", "#ff8042",
  "#a4de6c", "#d0ed57", "#8dd1e1", "#83a6ed",
  "#ffbb28", "#ff7300", "#00c49f", "#ff9999"
];

const PieCharts = ({ thermostatDetails, actorDetails }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!thermostatDetails || Object.keys(thermostatDetails).length === 0) {
    return <div>No data available for thermostat details.</div>;
  }

  const dynamicChartWidth = isMobile ? 400 : 600;
  const dynamicChartHeight = isMobile ? 500 : 450;
  const dynamicOuterRadius = isMobile ? 100 : 140;

  const dynamicLegendLayout = isMobile ? "horizontal" : "vertical";
  const dynamicAlign = isMobile ? "center" : "right";
  const dynamicVerticalAlign = isMobile ? "bottom" : "middle";

  const dynamicLegendWrapperStyle = {
    marginTop: 10,
    whiteSpace: "normal",
    maxWidth: "100%",
  };

  // Combine thermostatDetails and actorDetails into a single set of unique devices
  const allDevices = [
    ...Object.keys(thermostatDetails || {}),
    ...Object.keys(actorDetails || {})
  ].filter((key, index, self) => !key.includes("#config") && self.indexOf(key) === index); // Remove duplicates and filter out _config

  // Create a fixed color mapping for each device
  const colorMapping = allDevices.reduce((acc, device, index) => {
    acc[device] = COLORS[index % COLORS.length]; // Assign a color from the fixed set
    return acc;
  }, {});

  // Generate pie chart data while ensuring consistent colors
  const generatePieData = (source, valueKey) =>
    Object.keys(source || {})
      .filter(key => !key.includes("#config"))
      .map(key => ({
        name: source[key]?.name || "Unknown",
        value: source[key]?.[valueKey] || 0,
        color: colorMapping[key] // Use the pre-defined color mapping
      }));

  // Combine and sort the data by consumption (biggest first)
  const consumptionData = [
    ...generatePieData(thermostatDetails, "total_consumption"),
    ...generatePieData(actorDetails, "total_consumption")
  ].sort((a, b) => b.value - a.value); // Sort descending by total consumption

  // Sort cost data in the same order as consumption data
  const costData = [
    ...generatePieData(thermostatDetails, "total_cost"),
    ...generatePieData(actorDetails, "total_cost")
  ].sort((a, b) => {
    const aIndex = consumptionData.findIndex(item => item.name === a.name);
    const bIndex = consumptionData.findIndex(item => item.name === b.name);
    return aIndex - bIndex; // Sort cost data to match consumption order
  });

  const renderLegend = (value, entry) => `${entry.payload.name}: ${entry.payload.value.toFixed(2)}`;

  if (consumptionData.length === 0 || costData.length === 0) {
    return <div>No valid data available to render thermostat pie charts.</div>;
  }

  return (
    <div className="pie-chart-container">
      <div className="pie-chart">
        <h4>Total Consumption by Thermostat (kWh)</h4>
        <PieChart width={dynamicChartWidth} height={dynamicChartHeight}>
          <Pie data={consumptionData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={dynamicOuterRadius}>
            {consumptionData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Legend layout={dynamicLegendLayout} verticalAlign={dynamicVerticalAlign} align={dynamicAlign} wrapperStyle={dynamicLegendWrapperStyle} formatter={renderLegend} />
          <Tooltip />
        </PieChart>
      </div>
      <div className="pie-chart">
        <h4>Total Cost by Thermostat (cents)</h4>
        <PieChart width={dynamicChartWidth} height={dynamicChartHeight}>
          <Pie data={costData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={dynamicOuterRadius}>
            {costData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Legend layout={dynamicLegendLayout} verticalAlign={dynamicVerticalAlign} align={dynamicAlign} wrapperStyle={dynamicLegendWrapperStyle} formatter={renderLegend} />
          <Tooltip />
        </PieChart>
      </div>
    </div>
  );
};

export default PieCharts;
