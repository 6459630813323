import React, { useState, useEffect } from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { format } from 'date-fns'
import { InfoBox } from './Components'
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import PieCharts from "./PieCharts";




const processData = (data) => {
  return Object.keys(data).map((time) => ({
    time,
    totalHour: data[time].total_hour,
    price: data[time].price,
    cost: data[time].cost,
    dayAvg: data[time].day_avg,
    belowAvg: data[time].below_avg || false
  }));
};

const isBeforeToday = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and ms for an accurate comparison
    return date < today;
  };  

const HeatingCharts = ({ currentDate, onPreviousDate, onNextDate, data }) => {
  
  
  if (!data) {
    return <div>Loading...</div>; // or render nothing or a placeholder
  }
  
  //const summaryData = data.total_consumption
  //const metadata = data.metadata

  const { metadata, total_consumption } = data;
  const thermostatDetails = metadata.thermostat_details;
  const actorDetails = metadata.actor_details ? metadata.actor_details : null;
  
  return (
    <>
    <div className="center-header">
      <h2>Electricity Price, Total Consumption and Cost</h2>
    </div>  
    <div className="center-text">
       <h3><button onClick={onPreviousDate}><MdArrowBack /></button> {metadata.date} {isBeforeToday(currentDate) && <button onClick={onNextDate}><MdArrowForward /></button>}</h3>
    </div>
      
     
      <SummaryChart summaryData={total_consumption} metadata={metadata}/>
      <p></p>
      
      <PieCharts thermostatDetails={thermostatDetails} actorDetails={actorDetails} />
      
      <div className="center-text"><h3>Thermostat Details</h3></div>
     
      {metadata.thermostat_id_list.map(thermostatId => (
        thermostatId.includes('#config') ? (
          <ConfigChart key={thermostatId} data={data} thermostatId={thermostatId} metadata={metadata} />
        ) : (
          <ThermostatChart key={thermostatId} data={data} thermostatId={thermostatId} metadata={metadata} />
        )
      ))}
      
    {metadata.actor_id_list.length > 0 ? (
      <>
        <div className="center-text"><h3>Consumption Control Details</h3></div>
        {metadata.actor_id_list.map(actorId => (
          <ConsumptionChart key={actorId} data={data} actorId={actorId} metadata={metadata} />
        ))}
      </>
    ) : null}
      
      
      
    </>
  );
};

const customizedLegend = [
  { value: 'Total Consumption - below day average', type: 'square', color: '#90ee90' },
  { value: 'Total Consumption - above day average', type: 'square', color: '#ff6961' },
  { value: 'Electricity Price', type: 'line', color: '#ff7300' },
  { value: 'Day Average', type: 'line', color: '#ffa089' }
];

const CustomBarShapeTotals = (props) => {
  const { x, y, width, height, payload } = props;
  const customFill = payload.belowAvg ? '#90ee90' : '#ff6961';  // Green if reserve heating, otherwise default color
  return <rect x={x} y={y} width={width} height={height} fill={customFill} />;
};


const SummaryChart = ({ summaryData, metadata }) => {
  
  
  const processedSummarydData = processData(summaryData);
  //const metadata = data.metadata;

  const summaryDataPoints = [
    { label: "Total Consumption", value: `${metadata.total_consumption} kWh` },  // Assuming units are kWh, add if needed

      ...(metadata.consumption_below_avg !== undefined && metadata.consumption_above_avg !== undefined ? 
        [{ label: "Consumption below avg / above avg", value: `${metadata.consumption_below_avg} / ${metadata.consumption_above_avg} kWh` }] 
        : []
      ),
    { label: "Total Cost", value: `c ${metadata.total_cost.toFixed(2)}` },  // Ensure formatting to two decimal places for currency  
    { label: "Average Spot Price (full 24h)", value: `c ${metadata.spot_avg}` },
    { label: "Realised Average Price", value: `c ${metadata.realised_avg}` },
    { label: "Estimated savings, for full days only. Savings = (Total Consumption x Avg Spot Price) - Total Cost", value: `c ${metadata.estimated_saving}` },
    { label: "Average outdoor temperature", value: `${metadata.outdoor_temp_avg} \u00b0C` },
  ];

// Line to plot the hourly cost  
//       <Line type="step" yAxisId="right" dataKey="cost" name="Hourly Cost" stroke="#00c49f" strokeWidth={2}/>
// fill="#8884d8"
  return (
    <>
    
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart data={processedSummarydData} margin={{ top: 20, right: 30, bottom: 20, left: 20 }}>
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="time" name="Hour" tickFormatter={(tickItem) => format(new Date(tickItem), 'HH')}  />
        <YAxis yAxisId="left" label={{ value: 'Consumption (kWh)', angle: 90, position: 'insideLeft' }} />
        <YAxis yAxisId="right" orientation="right" label={{ value: 'Price/Cost (c)', angle: 90, position: 'insideRight' }} />

        <Tooltip />
        <Legend payload={customizedLegend} />
        <Bar yAxisId="left" dataKey="totalHour" name="Total Consumption"  shape={<CustomBarShapeTotals/>} barSize={20} />
        <Line type="step" yAxisId="right" dataKey="price" name="Electricity Price" stroke="#ff7300" strokeWidth={2}/>
        <Line yAxisId="right" dataKey="dayAvg" name="Day Average" stroke="#ffa089" strokeDasharray="8" strokeWidth={2} dot={false}/>
 
      </ComposedChart>
    </ResponsiveContainer>
    <div>
      <InfoBox title="Summary" dataPoints={summaryDataPoints} />
    </div>
    </>
  );
};


const ThermostatChart = ({ data, thermostatId, metadata}) => {
  if (!data) return null;
  
  
  const deviceColors = [
    '#8884d8', // Purple
    '#82ca9d', // Green
    '#ffc658', // Yellow
    '#ff7300', // Orange
    '#f45b5b', // Red
    '#00c49f', // Teal
    '#a4de6c', // Light green
    '#9053c7'  // Orchid
  ];

{/*
  const processData = (thermostatData) => {
    return Object.keys(thermostatData).map(key => {
      const hourlyByDevice = thermostatData[key].hourly_by_device || {};
      const processedData = {
        time: key,
        targetTemp: thermostatData[key].target_temp,
        currentTemp: thermostatData[key].current_temp,
        hourlyConsumption: thermostatData[key].hourly_consumption || 0,
        ...hourlyByDevice  // Spread the individual device data into the object
      };
      //console.log(processedData)
      return processedData;
    });
  };
*/}

const processData = (thermostatData) => {
  return Object.keys(thermostatData).map(key => {
    const hourlyByDevice = thermostatData[key].hourly_by_device || {};
    const processedData = {
      time: key,
      targetTemp: thermostatData[key].target_temp,
      currentTemp: thermostatData[key].current_temp,
      hourlyConsumption: thermostatData[key].hourly_consumption || 0,
      ...hourlyByDevice,
      reserve_heating: thermostatData[key].reserve_heating || false  // Add the charging flag
    };
    //console.log(processedData)
    return processedData;
  });
};


  const thermostatName = metadata[thermostatId];
  const chartData = processData(data[thermostatId]);
  const infoData = metadata.thermostat_details[thermostatId];
  
  
  const thermostatInfo = [
    { label: "Total Consumption", value: `${infoData.total_consumption} kWh` },  // Assuming units are kWh, add if needed
    { label: "Average temperature", value: `${infoData.temp_avg}\u00b0C` },
    { label: "Min temperature", value: `${infoData.min_temp} \u00b0C` },
    { label: "Max temperature", value: `${infoData.max_temp} \u00b0C` }
  ];

  // Get the list of device keys
  const deviceKeys = Object.keys(chartData[0]).filter(key => 
    key !== 'time' && key !== 'targetTemp' && key !== 'currentTemp' && key !== 'hourlyConsumption'  && key !== 'reserve_heating'
  );

const CustomBarShape = (props) => {
  const { x, y, width, height, payload, index } = props;
  const customFill = payload.reserve_heating ? '#228b22' : deviceColors[index % deviceColors.length];  // Green if reserve heating, otherwise default color
  return <rect x={x} y={y} width={width} height={height} fill={customFill} />;
};

// Check if any entry in chartData has reserve_heating as true
const chargingOccurred = chartData.some(entry => entry.reserve_heating === true);

// Create a custom legend that includes both device keys and temperature lines
const customizedLegend = [
  // Add device keys to the legend
  ...deviceKeys.map((deviceKey, index) => ({
    value: deviceKey,
    type: 'square',
    color: deviceColors[index % deviceColors.length], // Regular color for non-charging
  })),
  // Conditionally add Charging legend if charging occurred
  ...(chargingOccurred
    ? deviceKeys.map((deviceKey, index) => ({
        value: `${deviceKey} - Charging`,
        type: 'square',
        color: '#228b22', // Green for charging
      }))
    : []),
  // Manually add Target Temperature and Measured Temperature
  { value: 'Target Temperature', type: 'line', color: '#8884d8' },
  { value: 'Measured Temperature', type: 'line', color: '#82ca9d' }
];


//<Legend payload={customizedLegend} />

  return (
    <div>
      
      <InfoBox title={thermostatName} dataPoints={thermostatInfo} />
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart data={chartData}>
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="time" name="Hour" tickFormatter={(tickItem) => format(new Date(tickItem), 'HH')} interval={3} />
          <YAxis yAxisId="left" orientation="left" label={{ value: 'Consumption (kWh)', angle: 90, position: 'insideLeft' }} domain={['auto', 'auto']} stroke="#413ea0"/>
          <YAxis yAxisId="right" orientation="right" label={{ value: 'Temperature (C)', angle: 90, position: 'insideRight' }} domain={[10, 'auto']} stroke="#8884d8"/>
          <Tooltip 
            formatter={(value, name, props) => {
            
              // Apply "Charging heat retainer" only to the device and consumption rows
              if (props.payload.reserve_heating && name !== 'Target Temperature' && name !== 'Measured Temperature') {
                return [`${value} - Charging heat retainer`, name];
              }
              return [value, name];
              
            }} 
          />
        {/* Custom Legend */}
        <Legend payload={customizedLegend.map(item => ({ value: item.value, type: item.type, color: item.color }))} />

        
        {/* Temperature Lines */}
        <Line yAxisId="right" type="stepAfter" dataKey="targetTemp" name="Target Temperature" stroke="#8884d8" strokeWidth={2} dot={false} />
        <Line yAxisId="right" type="monotone" dataKey="currentTemp" name="Measured Temperature" stroke="#82ca9d" strokeWidth={2} dot={false} />

          {/* Total Consumption Bar 
          <Bar yAxisId="left" dataKey="hourlyConsumption" name="Total Consumption" fill="#413ea0" /> */}
          {/* Stacked Bars for Device Consumption */}
          {deviceKeys.map((deviceKey, index) => (
            <Bar 
              yAxisId="left" 
              key={deviceKey} 
              dataKey={deviceKey} 
              name={deviceKey}  // Use the device name for the legend
              stackId="a" 
              shape={<CustomBarShape index={index} />}  // Use the custom shape to render bars  // Change color if charging
            // fill={deviceColors[index % deviceColors.length]}  // Use the colors array
            />
          ))}
          {/* Temperature Lines 
          <Line yAxisId="right" type="stepAfter" dataKey="targetTemp" name="Target Temperature" stroke="#8884d8" strokeWidth={2} dot={false} />
          <Line yAxisId="right" type="monotone" dataKey="currentTemp" name="Measured Temperature" stroke="#82ca9d" strokeWidth={2} dot={false} />*/}
        </ComposedChart>
      </ResponsiveContainer>
      <p></p>
    </div>
  );

// deviceColors[index % deviceColors.length]

};


const ConfigChart = ({ data, thermostatId, metadata}) => {
  if (!data) return null;
  
  
  const deviceColors = [
    '#8884d8', // Purple
    '#82ca9d', // Green
    '#ffc658', // Yellow
    '#ff7300', // Orange
    '#f45b5b', // Red
    '#00c49f', // Teal
    '#a4de6c', // Light green
    '#9053c7'  // Orchid
  ];



const processData = (thermostatData) => {
  return Object.keys(thermostatData).map(key => {
    const processedData = {
      time: key,
      targetTemp: thermostatData[key].target_temp,
      calibration: thermostatData[key].calibration,
      fanSpeed: thermostatData[key].fan_level,
      outdoorTemp: thermostatData[key].outdoor_temp
    };
    //console.log(processedData)
    return processedData;
  });
};


  const thermostatName = metadata.thermostat_details[thermostatId].name;
  const chartData = processData(data[thermostatId]);
  const infoData = metadata.thermostat_details[thermostatId];
  
  const configInfo = [
    { label: "AHP Target temperature", value: `${infoData.target_temp} \u00b0C` },  // Assuming units are kWh, add if needed
    { label: "Calibration value", value: `${infoData.calibration}\u00b0C` },
    { label: "Fan speed", value: `${infoData.fan_level}` },
    { label: "Outdoor temperature", value: `${infoData.outdoor_temp} \u00b0C` },
    { label: "Settings at", value: `${infoData.timestamp}` }
  ];
  


  return (
    <div>
      <InfoBox title={thermostatName} dataPoints={configInfo} />
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart data={chartData}>
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="time" name="Hour" tickFormatter={(tickItem) => format(new Date(tickItem), 'HH')} interval={3} />
          <YAxis yAxisId="left" orientation="left" label={{ value: 'Outdoor temperature (C)', angle: 90, position: 'insideLeft' }} domain={['auto', 'auto']} stroke="#413ea0"/>
          {/*}<YAxis yAxisId="rightTargetTemp" orientation="right" label={{ value: 'AHP Target temperature (C)', angle: 90, position: 'insideRight' }} domain={[15, 35]} stroke="#8884d8"/>
          <YAxis yAxisId="rightCalib" orientation="right" label={{ value: 'AHP Calibration (C)', angle: 90, position: 'insideRight' }} domain={[-2, 10]} stroke="#8884d8"/>
          <YAxis yAxisId="rightFanSpeed" orientation="right" label={{ value: 'AHP Fan speed', angle: 90, position: 'insideRight' }} domain={[0, 3]} stroke="#8884d8"/> */}
          <YAxis yAxisId="right" orientation="right" label={{ value: 'AHP Settings', angle: 90, position: 'insideRight' }} domain={['auto', 'auto']} stroke="#8884d8"/>
        
            {/* Temperature Lines */}
          <Line yAxisId="left" type="monotone" dataKey="outdoorTemp" name="Outdoor Temperature" stroke="#8884d8" strokeWidth={2} dot={false} />
          <Line yAxisId="right" type="stepAfter" dataKey="targetTemp" name="AHP Target Temperature" stroke="#82ca9d" strokeWidth={2} dot={false} />
          <Line yAxisId="right" type="stepAfter" dataKey="calibration" name="AHP Calibration" stroke="#ffc658" strokeWidth={2} dot={false} />
          <Line yAxisId="right" type="stepAfter" dataKey="fanSpeed" name="AHP Fan Speed" stroke="#ff7300" strokeWidth={2} dot={false} />
          <Tooltip/>
          <Legend/>
          
        </ComposedChart>
      </ResponsiveContainer>
      <p></p>
    </div>
  );

// deviceColors[index % deviceColors.length]


};

const ConsumptionChart = ({ data, actorId, metadata}) => {
  if (!data) return null;

  

  const processData = (actorData) => {
    return Object.keys(actorData).map(key => {
      const processedData = {
        time: key,
        output: actorData[key].output,
        consumption: actorData[key].hourly_consumption
      };
      //console.log(processedData)
      return processedData;
    });
  };

  
  const actorName = metadata.actor_details[actorId].name ? metadata.actor_details[actorId].name : 'unknown';
  const chartData = processData(data[actorId]);
  const infoData = metadata.actor_details[actorId];
  
  const configInfo = [
    { label: "Total consumption", value: `${infoData.total_consumption} kWh` },  // Assuming units are kWh, add if needed
    { label: "Total cost", value: `${infoData.total_cost} c` },
    { label: "Configuration profile", value: `${infoData.profile}` }
  ];
  
  

  return (
    <div>
      <InfoBox title={actorName} dataPoints={configInfo} />
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart data={chartData}>
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="time" name="Hour" tickFormatter={(tickItem) => format(new Date(tickItem), 'HH')} interval={3} />
          <YAxis yAxisId="left" orientation="left" label={{ value: 'Consumption (kWh)', angle: 90, position: 'insideLeft' }} domain={['auto', 'auto']} stroke="#413ea0"/>
          <YAxis yAxisId="right" orientation="right" label={{ value: 'Output', angle: 90, position: 'insideRight' }} domain={['auto', 'auto']} stroke="#8884d8"/>
        
            {/* Consumption Lines */}
          <Bar yAxisId="left" dataKey="consumption" name="Hourly Consumption" fill='#8884d8' barSize={20} />
          <Line yAxisId="right" type="stepAfter" dataKey="output" name="Output" stroke="#82ca9d" strokeWidth={2} dot={false} />
          
          <Tooltip/>
          <Legend/>
          
        </ComposedChart>
      </ResponsiveContainer>
      <p></p>
    </div>
  );
};

export default HeatingCharts;